// External packages
import * as React from "react"
import { graphql } from "gatsby"
import { Box, Text, Heading } from "theme-ui"
import useDetectScroll from "@smakss/react-scroll-direction"
import { IGatsbyImageData } from "gatsby-plugin-image"

// Components
import Layout from "../components/layouts"
import SEO from "../components/seo"
import ContentModules from "../components/Page/ContentModules/ContentModules"
import { Grid as BlogGrid } from "../components/v2/contentful/Grid"
import { Text as BlogText } from "../components/v2/contentful/Text"
import { ImageGallery } from "../components/v2/contentful/ImageGallery"

// Hooks
import useWindowSize from "../hooks/useWindowSize"

// Utilities
import { isMobileWindow } from "../utils/render-util"
import {
  isContentfulGrid,
  isContentfulImageGallery,
  isContentfulText,
  isContentfulVimeoVideo,
} from "../components/v2/contentful/utils"

// Contexts
import { useInterfaceContext } from "../context/InterfaceContext"

// Types
import {
  ContentfulGrid,
  ContentfulText,
  BreadcrumbsListItem,
  ContentfulImageGallery,
} from "../../types"
import { getBunnyImageSrc } from "../../src/utils/bunny-cdn"

interface ShopstoryModule {
  contentful_id: string
  internal: {
    type: string
  }
}

interface BlogPostData {
  post: {
    title: string
    slug: string
    module: (
      | ContentfulGrid
      | ContentfulText
      | ContentfulImageGallery
      | ShopstoryModule
    )[]
    showSidebar: boolean
    featuredImage: {
      gatsbyImageData: IGatsbyImageData
    }
    gapRow: "0px" | "16px" | "32px" | "60px" | "80px"
    width: "732px" | "1024px" | "Full"
  }
}

interface BlogPostProps {
  data: BlogPostData
  pageContext: {
    slug: string
    shopstoryContent: any
    seo: {
      breadcrumbsList: BreadcrumbsListItem[]
    }
  }
}

const BlogPostTemplate = ({ data, pageContext }: BlogPostProps) => {
  let { post } = data
  const windowSize = useWindowSize()
  const { scrollDir, scrollPosition } = useDetectScroll()
  const { isPromotionalVisible } = useInterfaceContext()
  const [headings, setHeadings] = React.useState([])
  const [currentHeading, setCurrentHeading] = React.useState(null)

  React.useEffect(() => {
    const headingElements = document
      .querySelector("[data-content]")
      .querySelectorAll("h1")
    const headings = Array.from(headingElements).map((el) => {
      return {
        label: el.innerText,
        positionY: el.getBoundingClientRect().top + window.scrollY,
      }
    })
    setHeadings(headings)
  }, [])

  React.useLayoutEffect(() => {
    function handleScroll() {
      if (headings.length === 0) {
        return
      }

      const scrollPosition = window.scrollY + window.innerHeight / 3

      const currentHeading = headings.reduce((prev, curr) => {
        return Math.abs(curr.positionY - scrollPosition) <
          Math.abs(prev.positionY - scrollPosition)
          ? curr
          : prev
      })

      setCurrentHeading(currentHeading.positionY)
    }

    window.addEventListener("scroll", handleScroll)

    handleScroll()

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [headings])

  return (
    <Layout almostWhite>
      <SEO
        metaImage={getBunnyImageSrc(
          post.featuredImage &&
            post.featuredImage.gatsbyImageData.images.fallback?.src
        )}
        title={post.title}
        breadcrumbsList={pageContext.seo.breadcrumbsList}
        canonicalUrl={`/projects/${post.slug}`}
      />
      <Box sx={{ position: "relative", paddingBlockStart: [null, 13] }}>
        {headings.length > 0 && post.showSidebar && (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              position: "sticky",
              top: 0,
              left: 0,
              zIndex: "anchorNavigation",
              backgroundColor: "grayscale.white",
              transform:
                scrollDir === "up" &&
                isPromotionalVisible &&
                scrollPosition.top > 86
                  ? "translateY(52px)"
                  : scrollDir === "up" &&
                    !isPromotionalVisible &&
                    scrollPosition.top > 52
                  ? "translateY(52px)"
                  : "translateY(0)",
              transition: "transform .25s",
              borderBlock: "1px solid",
              borderBlockColor: "grayscale.300",
              paddingBlockStart: 0,
              "@media (min-width: 1200px)": {
                width: "calc((100vw - 732px) / 2)",
                position: "absolute",
                backgroundColor: "transparent",
                borderBlockColor: "transparent",
                paddingBlockStart: 13,
                paddingBlockEnd: 15,
              },
            }}
          >
            <Box
              as="ul"
              sx={{
                display: "flex",
                gap: 4,
                alignItems: "center",
                width: "100%",
                height: 17,
                listStyleType: "none",
                zIndex: 1000,
                overflowX: "scroll",
                paddingInline: 4,
                marginBlock: 0,
                marginInline: "auto",
                marginBlockStart: 0,
                "@media (min-width: 1200px)": {
                  display: "block",
                  width: 35,
                  height: "auto",
                  position: "sticky",
                  top: 15,
                  overflowX: "visible",
                  paddingInline: 0,
                  marginBlockStart: 28,
                },
              }}
            >
              {headings.map((heading) => (
                <Box
                  as="li"
                  key={heading.label}
                  onClick={() => {
                    const offset = isMobileWindow(windowSize.width) ? -100 : -55
                    window.scrollTo({
                      top: heading.positionY + offset,
                      left: 0,
                      behavior: "smooth",
                    })
                  }}
                  sx={{
                    minWidth: "auto",
                    fontSize: "sm",
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                    "@media (min-width: 1200px)": {
                      marginBlockEnd: 3,
                    },
                  }}
                >
                  <Text
                    sx={{
                      display: "inline-block",
                      borderBlockEnd: "1px solid transparent",
                      borderBlockEndColor:
                        currentHeading === heading.positionY && "currentColor",
                      transition: "border-block-end-color .2s",
                      pointerEvents: "none",
                    }}
                  >
                    {heading.label}
                  </Text>
                </Box>
              ))}
            </Box>
          </Box>
        )}
        <Box
          sx={{
            position: "relative",
            width: "100%",
            maxWidth:
              post?.width === "Full"
                ? "none"
                : post?.width === "1024px"
                ? "1024px"
                : "732px",
            marginBlock: 10,
            marginInline: "auto",
            paddingInline: 4,
            "@media (min-width: 1200px)": {
              paddingBlockStart: 4,
            },
          }}
        >
          <Heading
            as="h1"
            sx={{
              fontSize: ["xl", "2rem"],
              textAlign: "center",
              fontWeight: "heading",
              marginBlock: 12,
            }}
          >
            {post.title}
          </Heading>
          <Box
            data-content
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: post?.gapRow,
            }}
          >
            {post.module
              ?.filter((i) => i.internal)
              .map((item) => {
                if (isContentfulGrid(item)) {
                  return <BlogGrid key={item.id} data={item} isInBlogPost />
                }
                if (isContentfulText(item)) {
                  return <BlogText key={item.id} data={item} />
                }
                if (isContentfulImageGallery(item)) {
                  return <ImageGallery key={item.id} data={item} />
                }
                if (isContentfulVimeoVideo(item)) {
                  return (
                    <Box
                      sx={{
                        iframe: {
                          display: "block",
                          width: "100%",
                          aspectRatio: "16 / 9",
                          border: 0,
                          marginBlockEnd: 0,
                        },
                      }}
                    >
                      <iframe
                        title="vimeo-player"
                        src={
                          item?.autoplay
                            ? item?.url +
                              "?autoplay=1&muted=1&portrait=0&byline=0"
                            : item?.url + "?portrait=0&byline=0"
                        }
                        allowFullScreen
                      />
                    </Box>
                  )
                }
                return null
              })}
            {/*  TODO: Delete ContentModules component when content is migrated to new modules */}
            <ContentModules
              allModules={post.module}
              sx={{
                ".hero": {
                  height: "100%",
                  marginY: "6px",
                },
                ".rich-text": {
                  padding: "0px",
                  marginY: "20px",
                  display: "block",
                  columnCount: [0, "2"],
                  columnGap: "20px",
                  lineHeight: "1.2",

                  "h1, h2, h3, blockquote": {
                    columnSpan: "all",
                    marginBottom: "30px",
                  },
                  blockquote: {
                    marginY: "60px",
                    p: {
                      textAlign: "center",
                      fontStyle: "italic",
                      fontSize: "38px",
                    },
                  },
                  b: {
                    display: "block",
                  },
                  p: {
                    whiteSpace: "normal",
                    fontSize: "18px",
                  },
                },
                ".slider": {
                  maxHeight: "585px",
                  marginY: "20px",
                  ".asset": {
                    "&.vertical": {
                      flexBasis: ["90%", "60%"],
                    },
                  },
                },
                ".tilesection": {
                  position: "relative",
                  marginY: "10px",

                  ".items": {
                    gap: "10px",
                    ".tile-title": {
                      bottom: 0,
                      padding: "8px",
                    },
                  },
                },
              }}
              shopstoryContent={pageContext.shopstoryContent}
            />
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

export default BlogPostTemplate

export const query = graphql`
  query BlogPostQuery($slug: String!) {
    post: contentfulBlogPost(slug: { eq: $slug }) {
      ...BlogPost
    }
  }
`
